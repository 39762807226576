import { useState } from "react";

import { UserProvider } from "../Providers";
import useAlerts from "./useAlerts";
import useModal from "./useModalComponent";

const useUsdAccount = (props) => {
  const customLoadFormService = UserProvider.getInfoUsdAccount;
  const customUpdateFormService = UserProvider.manualUpdateUsdAccount;

  const { showModal, confirmationModal, errorMessage } = useAlerts();
  const { handleOpenModal, handleCloseModal, openModal } = useModal();
  const [rejectionReason, setRejectionReason] = useState("");
  const [isRetryDisabled, setIsRetryDisabled] = useState(false);
  const [isRejectDisabled, setIsRejectDisabled] = useState(false);

  const handleChangeRejectionReason = (e) =>
    setRejectionReason(e.target.valued);

  const rejectUsdAccount = () => {
    handleOpenModal();
  };

  const handleRejectConfirmed = async () => {
    handleCloseModal(); // Close the modal

    try {
      const result = await UserProvider.rejectUsdAccount(props.id, {
        rejection_reason: rejectionReason,
      });

      if (result.data.message) {
        confirmationModal({
          text: result.data.message,
        });
      } else {
        errorMessage(result.data.errors.join(".<br/>"));
      }
    } catch (e) {}
  };

  const retryUsdAccountCreation = () => {
    showModal({
      text: "¿Quieres reintentar crear una cuenta en usd para el usuario?",
      confirmButtonText: "Sí, Reintentar!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const result = await UserProvider.retryUsdAccountCreation(props.id);

          if (result.data.message) {
            confirmationModal({
              text: result.data.message,
            });
          } else {
            errorMessage(result.data.errors.join(".<br/>"));
          }
        } catch (e) {}
      }
    });
  };

  const handleAccountStatusChange = (status) => {
    if (status === "active") {
      setIsRetryDisabled(true);
      setIsRejectDisabled(true);
    } else {
      setIsRetryDisabled(false);

      if (status == "rejected") {
        setIsRejectDisabled(false);
      } else {
        setIsRejectDisabled(true);
      }
    }
  };

  return {
    customLoadFormService,
    customUpdateFormService,
    rejectUsdAccount,
    retryUsdAccountCreation,
    handleAccountStatusChange,
    isRetryDisabled,
    isRejectDisabled,
    openModal,
    handleCloseModal,
    setRejectionReason,
    rejectionReason,
    handleRejectConfirmed,
    handleChangeRejectionReason,
  };
};

export default useUsdAccount;

import { apiCall } from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const acceptedWithdawal = async (id, payment_processor_select = '') => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.TRANSACTIONS.ACCEPTED_WITHDRAWAL,
        data: payment_processor_select ? { id, payment_processor_select } : { id },
    });
};

const acceptedWithdawalManual = async (id) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.ACCEPTED_WITHDRAWAL_MANUAL(id)
  });
};

const rejectedWithdrawal = async (id, description_state_change) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.REJECTED_WITHDRAWAL(id),
    data: { description_state_change }
  });
};

const cancelPayment = async (id) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.CANCEL_PAYMENT,
    data: { id },
  });
};

const assignHash = async (id, hash_result) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.ASSIGN_HASH(id),
    data: { hash_result },
  });
};

const rechargeLote = async (payload) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.RECHARGE_LOTE,
    data: {
      batch: payload,
    },
  });
};

const batchPayout = async (payload) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.BATCH_PAYOUT,
    data: {
      transaction: payload,
    },
  });
};

const bitcoinWithdrawal = async (payload) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.BITCOIN_WITHDRAWAL,
    data: payload,
  });
};

const getTransferRules = async () => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.TRANSFER_RULES,
  });
};

const deleteTransferRules = async (payload) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.DELETE_TRANSFER_RULES,
    data: payload,
  });
};

const createTransferRule = async (payload) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.CREATE_TRANSFER_RULES,
    data: payload,
  });
};

const createBankSection = async (payload) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.CREATE_BANK_SECTION,
    data: payload,
  });
};

const createRuleCountry = async (payload) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.CREATE_RULES_COUNTRY,
    data: payload,
  });
};

const addBalanceForAdmin = async (payload) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.ADD_BALANCE_FOR_ADMIN,
    data: payload,
  });
};

const getTransaction = async (id) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.GET_TRANSACTION(id)
  });
};

const createDispersionToFailedTransaction = async (id) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.CREATE_DISPERSION_TO_FAILED_TRANSFER(id)
  });
};

const getTotalAmountRecipient = async (document_number, iso_code_country) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.GET_TOTAL_AMOUNT_RECIPIENT(document_number, iso_code_country)
  });
};

const getTransactionComments = async (id, filter) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.GET_COMMENTS(id, filter),
  });
};

const postTransactionComments = async (id, data) => {
  return await apiCall({
    authorization: true,
    data,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.POST_COMMENTS(id),
  });
};

const updateLockedStatus = async (id, payload) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.UPDATE_LOCKED_STATUS(id),
    data: payload
  })
};

const createTransaction = async (payload) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.CREATE_TRANSACTION,
    data: payload
  });
};

const removeFromTransactionsBatch = async (id) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.REMOVE_FROM_TRANSACTION_BATCH(id)
  })
};

const getCryptoRisk = async (id) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.GET_CRYPTO_RISK(id)
  })
};

export default {
  addBalanceForAdmin,
  createRuleCountry,
  createBankSection,
  createTransferRule,
  deleteTransferRules,
  getTransferRules,
  acceptedWithdawal,
  acceptedWithdawalManual,
  rejectedWithdrawal,
  cancelPayment,
  assignHash,
  rechargeLote,
  batchPayout,
  bitcoinWithdrawal,
  getTransaction,
  createDispersionToFailedTransaction,
  getTotalAmountRecipient,
  updateLockedStatus,
  createTransaction,
  removeFromTransactionsBatch,
  getCryptoRisk,
  getTransactionComments,
  postTransactionComments,
};

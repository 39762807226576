import React from "react";
import { Filter, TextInput } from "react-admin";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput
      label={"Buscar"}
      source={"search_key"}
      helperText={"Busca por nombre"}
      alwaysOn
    />
  </Filter>
);

export default Filters;

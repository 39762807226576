import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ReactCountryFlag from 'react-country-flag';
import Button from '@material-ui/core/Button';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Swal from "sweetalert2";
import { createBrowserHistory as createHistory } from 'history';
import WarningIcon from '@material-ui/icons/Warning';
import { availableCountriesBeneficiariesTruora, availableCountriesUsersTruora, flagsCountries } from '../../../../Config/constants';

import styles from './styles';
import { toMoney, formatMoney } from "../../../../Helpers/MoneyHelper";
import { Rest, TransactionProvider } from "../../../../Providers";
import { Link } from "react-router-dom";

import { ReferenceField } from "react-admin";
import useAlerts from '../../../../hooks/useAlerts';
import { QUERY_PARAMS } from '../../../utils';
import { alertTypeToEmoji } from '../../../../Utils';
import { NeitcomStatus } from '../../../../Components';
import Alerts from '../../../Transactions/components/Alerts';

const TotalSentCurrentMonth = (props) => {
  const { record } = props;
  const {
    first_name,
    sender_email,
    last_name,
    email,
    account_bank,
    country,
    email_business,
    isTransaction,
    document_number,
    beneficiary,
    copyValues,
    fast_payouts_provider,
    sender,
    order,
  } = props;
  const {
    attributes: {
      first_name: remitent_first_name,
      last_name: remitent_last_name,
      biometric_verification: remitent_biometric_verification,
      country: remitent_country,
    }
  } = record;

  const { errorMessage } = useAlerts();

  const [beneficiaryAmount] = useState(null)

  const total_sent_this_month = useMemo(() => {
    return record ? record.attributes.total_sent_this_month : 0;
  }, [record]);

  const checkBeneficiary = async (email) => {
    try {
      let response = await Rest('GET_LIST', 'usuarios', QUERY_PARAMS(email))

      if (response.data.length === 0 && beneficiary) {
        window.open(`beneficiaries/${beneficiary.id}/show`, 'Vita')
      } else if (response.data.length === 1) {
        window.open(`usuarios/${response.data[0].id}/show`, 'Vita')
      } else {
        let response = await Rest('GET_LIST', 'beneficiaries', QUERY_PARAMS(props.document_number))

        if (response.data.length > 0) {
          window.open(`beneficiaries/${response.data[0].id}/show`, 'Vita')
        } else {
          errorMessage('No disponible')
        }
      }
    } catch (e) { }
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        {
          !isTransaction && sender_email && !email_business && (
            <div style={{ paddingBottom: 10, borderRadius: 5, display: 'inline-block', textAlign: 'center', alignItems: 'center', paddingTop: 10, backgroundColor: '#393939', fontSize: 14, width: beneficiaryAmount ? '49%' : '100%' }}>
              <div>Remitente</div>
              <div style={styles.remitent_container}>
                <ReactCountryFlag countryCode={flagsCountries[remitent_country]} style={styles.remitent_flag} svg />
                <label style={styles.remitent_label}>{remitent_first_name} {remitent_last_name}</label>
                {
                  (remitent_biometric_verification === 'biometric_accepted' || remitent_biometric_verification === 'biometric_rejected') && (
                    <CheckBoxIcon style={{ color: (remitent_biometric_verification === 'biometric_accepted' ? '#2ba92b' : 'red'), fontSize: 18 }} />
                  )
                }
              </div>
              <Link
                to={`usuarios?filter=%7B"id"%3A"${record.id}"%7D&order=DESC&page=1&perPage=10&sort=id`}
                style={styles.styleLink}
                target="_blank"
              >
                <div style={styles.item}>{sender_email} 🔎</div>
              </Link>
              <div>
                <NeitcomStatus
                  veredict={sender?.criminal_verification_status?.neitcom?.['veredict']}
                  has_ignored_items={sender?.criminal_verification_status?.neitcom?.['has_ignored_items']}
                  max_percentage={sender?.criminal_verification_status?.neitcom?.['max_percentage']}
                  provider={'neitcom'}
                />
              </div>
              {
                (
                  availableCountriesUsersTruora.includes(sender?.business_user?.lr_document_issuing_country?.name) ||
                  availableCountriesUsersTruora.includes(sender?.document_issuing_country_name)
                ) &&
                <div>
                  <NeitcomStatus
                    veredict={sender?.criminal_verification_status?.truora?.['veredict']}
                    has_ignored_items={sender?.criminal_verification_status?.truora?.['has_ignored_items']}
                    max_percentage={sender?.criminal_verification_status?.truora?.['max_percentage']}
                    provider={'truora'}
                  />
                </div>
              }
              <div style={{ paddingTop: 20 }}>
                Total enviado este mes
                <div style={{ fontSize: 16, color: '#5587D8', fontWeight: 'bold' }}>{total_sent_this_month ?
                  `${formatMoney(total_sent_this_month, "usd")} USD` :
                  `${formatMoney(0, "usd")} USD`}</div>
              </div>
            </div>
          )
        }
        {
          email_business && (
            <div style={{ paddingBottom: 10, borderRadius: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 10, backgroundColor: '#00687c', fontSize: 14 }}>
              <div style={{ ...styles.item, fontSize: 30, color: '#00d9ff', fontWeight: 'bold' }}>Vita Business</div>
              <div style={styles.remitent_container}>
                <ReactCountryFlag countryCode={flagsCountries[remitent_country]} style={styles.remitent_flag} svg />
                <label style={styles.remitent_label}>{remitent_first_name} {remitent_last_name}</label>
                {
                  (remitent_biometric_verification === 'biometric_accepted' || remitent_biometric_verification === 'biometric_rejected') && (
                    <CheckBoxIcon style={{ color: (remitent_biometric_verification === 'biometric_accepted' ? '#2ba92b' : 'red'), fontSize: 18 }} />
                  )
                }
              </div>
              <Link
                to={`usuarios?filter=%7B"id"%3A"${record.id}"%7D&order=DESC&page=1&perPage=10&sort=id`}
                style={styles.styleLink}
                target="_blank"
              >
                <div style={styles.item}>{email_business} 🔎</div>
              </Link>
              <div>
                <NeitcomStatus
                  veredict={sender?.criminal_verification_status?.neitcom?.['veredict']}
                  has_ignored_items={sender?.criminal_verification_status?.neitcom?.['has_ignored_items']}
                  max_percentage={sender?.criminal_verification_status?.neitcom?.['max_percentage']}
                  provider={'neitcom'}
                />
              </div>
              {
                (
                  availableCountriesUsersTruora.includes(sender?.business_user?.lr_document_issuing_country_name) ||
                  availableCountriesUsersTruora.includes(sender?.document_issuing_country_name)
                ) &&
                <div>
                  <NeitcomStatus
                    veredict={sender?.criminal_verification_status?.truora?.['veredict']}
                    has_ignored_items={sender?.criminal_verification_status?.truora?.['has_ignored_items']}
                    max_percentage={sender?.criminal_verification_status?.truora?.['max_percentage']}
                    provider={'truora'}
                  />
                </div>
              }
              <br />
              <div style={styles.item}>Orden</div>
              <div style={{ ...styles.item, width: '100%', textAlign: 'center', maxWidth: 300 }}>{order}</div>
            </div>
          )
        }

        {
          !isTransaction && beneficiaryAmount && (
            <div style={{ marginLeft: '2%', paddingBottom: 10, borderRadius: 5, display: 'inline-block', textAlign: 'center', alignItems: 'center', paddingTop: 10, backgroundColor: '#393939', fontSize: 14, width: '49%' }}>
              <div>Beneficiario</div>
              <div style={styles.item}>{document_number}</div>
              <div style={{ paddingTop: 20 }}>
                Total recibido este mes
                <div style={{ fontSize: 16, color: '#5587D8', fontWeight: 'bold' }}>{beneficiaryAmount}</div>
              </div>
            </div>
          )
        }
      </div>
      {/* {
                !isTransaction && !beneficiaryAmount && (
                    <Button onClick={getTotalAmountRecipient} style={styles.buttonBlue}>
                        Total recibido por el destinatario
                    </Button>
                )
            } */}
      <br />
      <ReactCountryFlag countryCode={flagsCountries[country]} style={styles.flag} svg />{country === 'Estados Unidos - WIRE' ? (<span style={{ fontSize: 24, marginLeft: 4 }}>WIRE</span>) : country === 'Colombia2' ? (<span style={{ fontSize: 24, marginLeft: 4 }}>2</span>) : null}&nbsp;&nbsp;{fast_payouts_provider ? (<span style={{ fontSize: 24 }}>{fast_payouts_provider.toUpperCase()}</span>) : null}<br /><br />
      <div style={styles.item}>
        <div style={{ opacity: 0.5 }}>{account_bank["company_name"] ? "Empresa" : "Natural"}</div>
        {
          first_name || account_bank?.company_name || beneficiary?.first_name
        } {
          last_name || beneficiary?.last_name || ''
        }
        <span className="clickable" onClick={() => copyValues(`${first_name ? first_name : account_bank.company_name} ${last_name ? last_name : ''}`)}>📝</span>
      </div>
      <button
        style={styles.textEmail}
        onClick={() => checkBeneficiary(email)}>
        {email}
      </button>
      <span onClick={() => copyValues(email)} className='clickable'> 📝</span>
      {!sender_email && !email_business && (
        <div style={{ paddingTop: 20 }}>
          Total enviado este mes
          <div style={{ fontSize: 30, color: '#00d9ff', fontWeight: 'bold' }}>{total_sent_this_month ?
            `${formatMoney(total_sent_this_month, "usd")} USD` :
            `${formatMoney(0, "usd")} USD`}</div>
        </div>
      )}<br /><br />
    </>
  );
};

const AccountBankCustom = (props) => {
  const history = createHistory({ forceRefresh: true });
  const {
    copyValues,
    transaction_id,
    is_dlocal,
    document_type,
    document_number,
    account_bank,
    description,
    order,
    email_business,
    iso_code_country,
    beneficiary,
    currency,
    active_alerts,
    alert_count,
    decimals_destination,
    propsList,
    fast_payouts_provider,
    sender,
  } = props;
  const {
    record: {
      attributes: {
        amount_local_currency
      }
    }
  } = propsList || { record: { attributes: {} } }

  const { criminal_verification_status } = beneficiary || {};
  const { neitcom, truora } = criminal_verification_status || {};
  const { successMessage } = useAlerts();

  const documentType = () => {
    switch (document_type) {
      case 'dni': {
        return 'Documento de identidad';
      }
      case 'passport': {
        return 'Pasaporte';
      }
      case 'dni_foreigner': {
        return 'Documento de extranjería';
      }
      case 'company_id': {
        return 'Documento de empresa';
      }
      case 'company_ruc': {
        return 'Registro único de contribuyentes';
      }
      case 'CC': {
        return 'Cedula de ciudadanía';
      }
      case 'CE': {
        return 'Carnet de extranjería';
      }
      case 'NIT': {
        return 'Número de identificación tributaria';
      }
      case 'M': {
        return 'Cuenta maestra';
      }
      case 'PASS': {
        return 'Pasaporte';
      }
      case 'CURPE': {
        return 'Clave única de registro de población de empresas';
      }
      case 'CURP': {
        return 'Clave única de registro de población';
      }
      case 'DNI': {
        return 'Documento nacional de identidad';
      }
      case 'ID': {
        return 'Documento de identificación de empresas';
      }
      default: {
        return document_type;
      }
    }
  };

  const accountType = () => {
    if (account_bank) {
      switch (account_bank.type_account_bank) {
        case 'S': {
          return 'Cuenta de ahorros';
        }
        case 'C': {
          return 'Cuenta corriente';
        }
        case 'M': {
          return 'Cuenta maestra';
        }
        default: {
          return account_bank.type_account_bank;
        }
      }
    }

    return '';
  };

  const filterAmount = async (amount) => {
    let lessDecimals = amount.split('.');
    if (decimals_destination > 0) {
      if (lessDecimals[1].length > decimals_destination) {
        lessDecimals[1] = lessDecimals[1].slice(0, decimals_destination);
        amount = lessDecimals.join('.');
      } else {
        amount = parseFloat(amount).toFixed(decimals_destination);
      }
    } else {
      amount = parseFloat(lessDecimals[0])
    }
    await navigator.clipboard.writeText(amount);

    successMessage(`Valor copiado ${amount}`)
  }

  const descriptionRender = () => {
    return (
      <div>
        <p><strong>Descripción:</strong><br />{description}</p>
        <button
          style={styles.textTitle}
          onClick={() => filterAmount(amount_local_currency)}>
          {`Monto a recibir ${formatMoney(amount_local_currency, currency.toUpperCase())} ${currency.toUpperCase()}`}
        </button>
      </div>
    )
  }

  return (
    <ul>
      {is_dlocal && (
        <div style={{ paddingBottom: 25 }}>
          <Button
            style={{ backgroundColor: '#00a6ff' }}
            onClick={() => {
              window.location = `/dlocal?filter={"transaction_id"%3A"${transaction_id}"}&order=DESC&page=1&perPage=10&sort=id`
            }}
          >
            Ver registros de Dlocal
          </Button>
        </div>
      )}
      {props.propsList && (
        <ReferenceField
          {...props.propsList}
          label={'Cliente'}
          source={'attributes.sender_id'}
          reference={'users'}
          linkType={false}
        >
          <TotalSentCurrentMonth {...props} fast_payouts_provider={fast_payouts_provider} />
        </ReferenceField>
      )}
      {
        beneficiary?.is_banned && (
          <div style={{ ...styles.item, color: 'red' }}>
            Beneficiario baneado
            <WarningIcon style={{ color: 'red', marginLeft: '5px', marginRight: '5px', verticalAlign: 'bottom', fontSize: 'large' }} />
          </div>
        )
      }
      <div style={styles.item}>{documentType()} <span onClick={() => copyValues(document_type)} className='clickable'>📝</span></div>
      <div style={styles.item}>{document_number} <span onClick={() => copyValues(document_number)} className='clickable'>📝</span></div>
      {neitcom && (
        < NeitcomStatus
          veredict={neitcom?.['veredict']}
          has_ignored_items={neitcom?.['has_ignored_items']}
          max_percentage={neitcom?.['max_percentage']}
          provider={'neitcom'}
        />
      )}
      <br />
      {truora && availableCountriesBeneficiariesTruora.includes(iso_code_country) && (
        < NeitcomStatus
          veredict={truora?.['veredict']}
          has_ignored_items={truora?.['has_ignored_items']}
          max_percentage={truora?.['max_percentage']}
          provider={'truora'}
        />
      )}
      <br /><br />
      {
        account_bank && account_bank.transfer_type &&
        <div style={styles.item}>{account_bank.transfer_type}</div>
      }
      <div style={styles.item}>{account_bank && account_bank.bank ? account_bank.bank : (iso_code_country === "BR" ? "Código PIX" : 'No registra banco')}</div>
      <div style={styles.item}>{accountType()}</div>
      {
        account_bank && account_bank.transfer_type && account_bank.transfer_type === 'Pago movil' ?
          <div style={styles.item}>
            {account_bank && account_bank.associate_phone ? account_bank.associate_phone : 'No registra cuenta'} <span className='clickable' onClick={() => copyValues(account_bank.associate_phone.slice(-7))}>📝</span>
          </div> :
          <div style={styles.item}>
            {account_bank && account_bank.account_bank ? account_bank.account_bank : 'No registra cuenta'} <span className='clickable' onClick={() => copyValues(account_bank.account_bank)}>📝</span>
          </div>
      }
      {iso_code_country !== undefined && iso_code_country.toLowerCase() === 'us' && (
        <>
          <div style={styles.item}>ROUTING NUMBER: {account_bank.routing_number}</div>
          <br />
        </>
      )}
      {iso_code_country !== undefined && account_bank.swift_bic && (
        <>
          <div style={styles.item}>SWIFT: {account_bank.swift_bic}</div>
          <br />
        </>
      )}
      {iso_code_country !== undefined && iso_code_country.toLowerCase() === 'br' && (
        <>
          <div style={styles.item}>BANK BRANCH: {account_bank.bank_branch}</div>
          <br />
        </>
      )}
      <br /><br />
      {/* <div style={styles.item}>{description}</div> */}
      {
        amount_local_currency && (
          <div style={styles.item}>{descriptionRender()}</div>
        )
      }
      <Alerts
        transaction_id={transaction_id}
        active_alerts={active_alerts}
        alert_count={alert_count}
      />
      <br />
    </ul>
  );
};

AccountBankCustom.propTypes = {
  transaction_id: PropTypes.string,
  is_dlocal: PropTypes.bool,
  description: PropTypes.string,
  country: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  order: PropTypes.string,
  document_number: PropTypes.string,
  document_type: PropTypes.string,
  url_notify: PropTypes.string,
  account_bank: PropTypes.shape({
    bank: PropTypes.string,
    type_account_bank: PropTypes.string,
    account_bank: PropTypes.string,
  }),
  isTransaction: PropTypes.bool,
  active_alerts: PropTypes.array,
  alert_count: PropTypes.number,
};

AccountBankCustom.defaultProps = {
  transaction_id: 0,
  is_dlocal: false,
  description: '',
  country: '',
  first_name: '',
  last_name: '',
  email: '',
  document_number: '',
  document_type: '',
  email_business: '',
  order: '',
  account_bank: {
    bank: '',
    type_account_bank: '',
    account_bank: '',
  },
  isTransaction: false,
  active_alerts: [],
  alert_count: 0,
};

export default AccountBankCustom;

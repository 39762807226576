import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, TextField, Switch } from '@material-ui/core';
import ReactCountryFlag from 'react-country-flag';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';

import TransactionProvider from '../../../../Providers/Transactions';
import { SenderOrRecipientInfo } from '../../Helpers';
import { RESOLVE_STATUS_TRANSACTIONS } from '../../../../Helpers/ResourceHelper';
import { flagsCountries } from '../../../../Config/constants';

import styles from './styles';
import { useReset, useAccessRole } from '../../../../hooks';
import { alertTypeToEmoji } from '../../../../Utils';

const InformationTransaction = (props) => {
  const {
    history,
    id,
    attributes: {
      is_create_dispersion_to_failed,
      currency,
      status,
      category,
      sender_email,
      buy_order,
      recipient_email,
      created_at,
      hash_result,
      external_provider,
      external_id,
      utility_name,
      utility_number,
      client_number,
      amount,
      coupon_user,
      airtable_mirror,
      sender_id,
      transactions_batch,
      active_alerts,
      account_bank,
      external_service_provider,
      network,
      sender_user,
      recipient_user,
    }
  } = props;
  const [hash, setHash] = useState('');
  const [isVisibleHash, setVisibleHash] = useState(false);
  const { resetList } = useReset();
  const { isAccess } = useAccessRole();

  const changeHash = (e) => {
    setHash(e.target.value);
  };

  const changeVisibleHash = () => {
    setVisibleHash(!isVisibleHash);
  };

  const assignHash = async () => {
    try {
      await TransactionProvider.assignHash(id, hash);
    } catch (e) { }

    history.push(`/transacciones?filter={"id"%3A"${id}"}&order=DESC&page=1&perPage=10&sort=attributes.created_at`);
  };

  const createDispersionToFailedTransaction = async () => {
    try {
      await TransactionProvider.createDispersionToFailedTransaction(id);
    } catch (e) { }

    resetList();
  };

  const toTransactionsStatus = () => {
    history.push(`/estados%20de%20las%20transacciones?filter={"id"%3A"${id}"}`);
  };

  const removeFromTransactionsBatch = async () => {
    const message = transactions_batch.external_id ? `La transacción se encuentra en el lote "${transactions_batch.external_id}".
        Para realizar esta acción, asegúrese de que la carga de este lote arrojo algún error desde Bancolombia.` : `La transacción ya no estara en el lote actual`;

    Swal.fire({
      title: "¿Estás seguro?",
      text: message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await TransactionProvider.removeFromTransactionsBatch(id);
        } catch (e) {
          Swal.fire({
            title: "Error!",
            text: e.data.error ? e.data.error : 'Ocurrio un error',
            icon: "warning",
            background: "rgb(19,19,19)",
            confirmButtonText: 'Ok',
            showCancelButton: false,
          });
        }
      }

      resetList();
    });
  }

  const renderHashButton = () => {
    if (currency === 'btc' && category === 'sent' && status === 'pending')
      return (
        <>
          <div style={{ paddingLeft: 20 }}>
            <Switch
              checked={isVisibleHash}
              onChange={changeVisibleHash}
              color='primary'
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            Hash
          </div>
          {
            isVisibleHash ? (
              <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                <div>
                  <TextField
                    style={{ width: '100%' }}
                    value={hash}
                    onChange={changeHash}
                  />
                </div>
                <div style={{
                  paddingTop: 10,
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center'
                }}
                >
                  <Button style={styles.button} onClick={assignHash}>
                    GUARDAR
                  </Button>
                </div>
              </div>
            ) : null
          }
        </>
      );
  };

  return (
    <ul>
      <div style={styles.item}>Fecha</div>
      <div style={styles.item}>
        {moment.utc(created_at).format('LLLL')} UTC
      </div>
      <br /><br />
      {/* <div style={styles.item}>Estado</div>
            <div style={styles.item}>{RESOLVE_STATUS_TRANSACTIONS(props)}</div><br /><br /> */}
      {category !== 'service_payment' && (
        <>
          <div style={styles.item}>Estado</div>
          <div style={styles.item}>{RESOLVE_STATUS_TRANSACTIONS(props)}</div>
          <br /><br />
        </>
      )}
      {SenderOrRecipientInfo.senderInfo(props) && (
        <div>
          <div style={styles.item}>Remitente</div>
          <div style={styles.item}>
            {SenderOrRecipientInfo.senderInfo(props)}
          </div>
          <br /><br />
        </div>
      )}
      {SenderOrRecipientInfo.recipientInfo(props) && (
        <div>
          <div style={styles.item}>Destinatario</div>
          <div style={styles.item}>
            {SenderOrRecipientInfo.recipientInfo(props)}
          </div>
          <br /><br />
        </div>
      )}
      {hash_result && (
        <div>
          <div style={styles.item}>Hash resultante</div>
          <div style={styles.item}>{hash_result}</div>
          <div style={styles.item}>{network}</div>
          <br /><br />
        </div>
      )}
      {buy_order && (
        <div>
          <div style={styles.item}>Orden de compra</div>
          <div style={styles.item}>{buy_order}</div>
          <br /><br />
        </div>
      )}
      {sender_email && (
        <div>
          <div style={styles.item}>Email del remitente</div>
          <Link
            to={`usuarios/${sender_id}/show`}
            style={styles.styleLink}
            target="_blank"
          >
            <div style={{ ...styles.item, ...styles.cotainer_with_flag }}>
              <ReactCountryFlag countryCode={flagsCountries[sender_user?.data?.attributes?.country]} style={styles.flag} svg /> {sender_email}  🔎
            </div>
            <br /><br />
          </Link>
        </div>
      )}
      {utility_name && (
        <div>
          <div style={styles.item}>Nombre del convenio</div>
          <div style={styles.item}>{utility_name}</div>
          <br /><br />
        </div>
      )}
      {utility_number && recipient_user &&
        recipient_user.data && recipient_user.data.id && (
          <div>
            <div style={styles.item}>Número de convenio</div>
            <div style={styles.item}>{utility_number}</div>
            <br /><br />
            <div style={styles.item}>Email del destinatario</div>
            <Link
              to={`usuarios/${recipient_user.data.id}/show`}
              style={styles.styleLink}
              target="_blank"
            >
              <div style={styles.item}>{recipient_email} 🔎</div>
              <br /><br />
            </Link>
          </div>
        )}
      {client_number && (
        <div>
          <div style={styles.item}>Número de cliente</div>
          <div style={styles.item}>{client_number}</div>
          <br /><br />
        </div>
      )}
      {category !== 'service_payment' && recipient_email && recipient_user &&
        recipient_user.data && recipient_user.data.id && (
          <div>
            <div style={styles.item}>Email del destinatario</div>
            <Link
              to={`usuarios/${recipient_user.data.id}/show`}
              style={styles.styleLink}
              target="_blank"
            >
              <div style={{ ...styles.item, ...styles.cotainer_with_flag }}>
                <ReactCountryFlag countryCode={flagsCountries[recipient_user?.data?.attributes?.country]} style={styles.flag} svg /> {recipient_email}  🔎
              </div>
              <br /><br />
            </Link>
          </div>
        )}
      {external_provider && (
        <div>
          <div style={styles.item}>Proveedor de pago</div>
          <div style={styles.item}>{external_provider.toUpperCase()}</div>
          <br /><br />
        </div>
      )}
      {external_service_provider && (
        <div>
          <div style={styles.item}>Servicio del proveedor externo</div>
          <div style={styles.item}>{external_service_provider.toUpperCase()}</div>
          <br /><br />
        </div>
      )}
      {external_id && (
        <div>
          <div style={styles.item}>ID del proveedor</div>
          <div style={styles.item}>{external_id}</div>
          <br /><br />
        </div>
      )}
      {airtable_mirror && (
        <div>
          <div style={styles.item}>Transacción Airtable</div>
          <div style={styles.item}>
            TX Proveedor: {airtable_mirror['tx_rsv'] || 'sin asignar'}
          </div>
          <div style={styles.item}>
            TX Banco: {airtable_mirror['tx_bank'] || 'sin asignar'}
          </div>
          <br /><br />
        </div>
      )}
      {external_provider && external_provider.toUpperCase() === 'DLOCAL' && (
        <div style={{ paddingBottom: 25 }}>
          <Button
            style={{ backgroundColor: '#00a6ff' }}
            onClick={() => {
              window.location = `/dlocal?filter={"transaction_id"%3A"${id}"}&order=DESC&page=1&perPage=10&sort=id`
            }}
          >
            Ver registros de Dlocal
          </Button>
        </div>
      )}
      {is_create_dispersion_to_failed && (
        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
          <Button
            style={{ backgroundColor: '#00a6ff' }}
            onClick={createDispersionToFailedTransaction}
          >
            Crear dispersión de transferencia fállida
          </Button>
        </div>
      )}
      {coupon_user && (
        <div>
          <div style={styles.item}>Cupón</div>
          <div style={styles.item}>{coupon_user.coupon.identifier}</div>
          <div style={styles.item}>Tipo</div>
          <div style={styles.item}>{coupon_user.coupon.coupon_type}</div>
          <br /><br />
        </div>
      )}
      {renderHashButton()}
      <div>
        <Button
          style={styles.button}
          onClick={toTransactionsStatus}
        >
          VER HISTORIAL DE ESTADOS
        </Button>
      </div>
      {isAccess('remove_from_transaction_batch') && transactions_batch && (transactions_batch.status === 'pending' ||
        transactions_batch.status === 'uploaded') && transactions_batch.provider === 'bancolombia' && (
          <div>
            <br />
            <Button
              style={{ ...styles.button, backgroundColor: 'rgb(255, 0, 0)' }}
              onClick={removeFromTransactionsBatch}
            >
              SACAR DE LOTE DE TRANSACCIONES
            </Button>
          </div>
        )}

      {account_bank === null &&
        (<div style={styles.emojiContainer}>
          {
            active_alerts && active_alerts.map((alert_type, key) => (
              <span key={key} style={styles.emoji}>
                {alertTypeToEmoji(alert_type)}
              </span>
            ))
          }
        </div>)
      }
    </ul>
  );
};

InformationTransaction.propTypes = {
  attributes: PropTypes.shape({
    sender_email: PropTypes.string,
    buy_order: PropTypes.string,
    recipient_email: PropTypes.string,
    created_at: PropTypes.string,
  }),
};

InformationTransaction.defaultProps = {
  attributes: {
    sender_email: '',
    buy_order: '',
    recipient_email: '',
    created_at: '',
  },
};

export default InformationTransaction;

import React               from 'react';
import { createMuiTheme }  from '@material-ui/core/styles';
import { Admin, Resource } from 'react-admin';

import styles                                     from './styles';
import Navigation                                 from './Navigation';
import Resources                                  from './Resources';
import { Auth }                             from './Providers';
import { Dashboard, Layout, Login, LogoutButton } from './Components';
import { RestAdmin } from './Providers/Rest';
import EventsScheduler from './Components/EventsScheduler';


const App = () => {
    const theme = createMuiTheme(styles);

    const renderResources = () => {
        return Resources.map((resource) => <Resource {...resource} />);
    };

    return(
        <>
            <EventsScheduler />
            <Admin
                loginPage={Login}
                logoutButton={LogoutButton}
                authProvider={Auth}
                dataProvider={RestAdmin}
                appLayout={Layout}
                history={Navigation.nav().history}
                theme={theme}
                dashboard={Dashboard}
            >
                {renderResources()}
                <Resource name={'users'}/>
                <Resource name={'balance'}/>
                <Resource name={'btc_addresses'}/>
            </Admin>
        </>
    );
};

export default App;

import React from "react";
import { Filter, TextInput } from "react-admin";

const SegmentFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput
        label={"Buscar"}
        source={"name_segment"}
        helperText={"Busca por nombre de segmento"}
        alwaysOn
      />
    </Filter>
  );
};

export default SegmentFilter;

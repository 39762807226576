import React from 'react';
import { Datagrid, List, TextField, Filter, SelectInput } from 'react-admin';

import Navigation from '../../../Navigation';

import PayIn from '../components/PayIn';
import CreateCustodialAccountHoc from '../components/CreateCustodialAccount';
import { LIST_TYPE, ACCOUNTS_FIELDS, IDENTITIES_FIELD, getType } from '../utils'; 

const Filters = (props) => (
	<Filter {...props}>
        <SelectInput
            label={'Tipo'}
            source={'type'}
            choices={LIST_TYPE}
            alwaysOn
        />
    </Filter>
);

const useFortressList = (props = {}) => {
	const type = getType(Navigation.nav().history);

	return {
		fields: type === 'accounts' ? 
			ACCOUNTS_FIELDS : 
			IDENTITIES_FIELD,
		type,
		isVisible: !document.querySelector('[aria-label="Refresh"]'),
	}
};

const FortressList = (props) => (
    <List
		{...props}
		filters={<Filters />}
		title={'Fortress'}
	>
		<Datagrid>
			{props.isVisible && props.fields.map((field) => (
				<TextField 
					key={field}
					{...field}
			    />
			))}
            {props.isVisible && props.type === 'accounts' && <PayIn />}
			{props.isVisible && props.type === 'identities' && <CreateCustodialAccountHoc />}
		</Datagrid>
	</List>
);

const FortressListHoc = (props) => {
	const hook = useFortressList(props);
	return <FortressList {...hook} {...props} />;
};

export default FortressListHoc;
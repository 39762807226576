import React, { useState } from 'react';
import moment from 'moment';
import {
  Grid, TextField, Card, Avatar, CardHeader, Typography, CardContent, Chip,
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CachedIcon from '@material-ui/icons/Cached';
import { IconButton } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';

import UserStatusLogTable from '../UserStatusLogsTable';
import { BalanceField } from "../../../../../Components";

import useAccessRole from '../../../../../hooks/useAccessRole';
import { availableCountriesUsersTruora, biometricStatus } from "../../../../../Config/constants";

import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

import styles from "./styles";
import BtcInformationUser from "../../../../../Components/BtcInformationUser";
import NeitcomStatusLogs from '../NeitcomStatusLogs';
import Cards from '../Cards';
import TransferCurrenciesModal from '../Modals/TransferCurrencies'
import PoliticallyExposedPersonInfo from '../PoliticallyExposedPersonInfo';
import NaturalUserData from './NaturalUserData';
import BusinessUserData from './BusinessUserData';
import Referrer from './components/Referrer';
import Suppress from './components/Suppress';
import EditButton from './components/EditButton';
import AttachmentsPanel from '../../../UserEdit/CustomComponents/Attachments';
import { useReset } from '../../../../../hooks';
import { TYPE_PROFILE_VERIFICATION } from '../../../../../Utils';
import { useAlerts } from '../../../../../hooks';
import { UserProvider } from '../../../../../Providers';
import UsdNaturalAccount from './UsdNaturalAccount';
import { MAP_PREFERENTIAL_TYPES_TO_NAMES } from '../../../UserEdit/CustomComponents/utils';
import ListLinks from '../../../UserEdit/CustomComponents/BusinessLinkPayment/components/ListLinks';
import { UltimateBusinessOwners } from './components';

const Show = (props) => {
  const { successMessage, errorMessage } = useAlerts();
  const { record, isLoading } = props;
  const { isAccess } = useAccessRole();
  const { reloadPage } = useReset();
  const [isCreatingCLABE, setIsCreatingCLABE] = useState(false);

  if (isLoading || !record || !record?.attributes) return <></>;

  const {
    id,
    country: {
      attributes: {
        iso_code: country_iso_code
      } = {}
    } = {},
    attributes: {
      first_name,
      last_name,
      birthdate,
      country,
      last_sign_in_at,
      password_last_changed_at,
      default_currency,
      email,
      accept_funds,
      profile_completed,
      accept_pep,
      accept_real_info,
      devices_list,
      profile_verification,
      auto_convert_to_local_currency,
      email_confirmed,
      second_factor,
      biometric_verification,
      source_biometric,
      criminal_verification = {},
      preferential_prices,
      balances,
      is_sent_available,
      status,
      category,
      referral_user,
      crypto_accounts,
      document_issuing_country_name,
      business_user,
      referral_token,
      stp_clabe_number,
      list_card_active,
      list_card_not_active,
      ultimate_business_owners
    }
  } = record;

  const assignSTPCLABENumber = async () => {
    try {
      setIsCreatingCLABE(true);
      await UserProvider.assignSTPCLABENumber(id);
      reloadPage();
    } catch (error) {
      setIsCreatingCLABE(false);
      errorMessage(error?.data?.message || 'No se pudo asignar un numero de cuenta CLABE');
    }
  };

  return (
    <Card style={styles.card}>
      {
        !first_name && !last_name && !birthdate ? null : (
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" style={{ backgroundColor: '#00d7f5' }}>
                {first_name ? first_name[0].toUpperCase() : ''}
              </Avatar>
            }
            title={`${first_name ? first_name : ''} ${last_name ? last_name : ''}`}
            subheader={`${birthdate ? `${moment(birthdate).format('LL')}, ` : ''}Verificación Biométrica: ${biometricStatus[biometric_verification]}
                  ${(biometric_verification !== 'biometric_none' && biometric_verification !== 'false' ? `, solicitud desde el ${source_biometric}` : '')}`}
          />
        )
      }
      <div style={styles.wrapperButtons}>
        <TransferCurrenciesModal disabled={!(status === 'active' && is_sent_available)} balances={balances} senderEmail={email} senderId={id} />
        {isAccess('suppress_users') && <Suppress id={id} status={status} />}
        <EditButton id={id} />
      </div>
      <CardContent>
        <Typography variant="h4" gutterBottom style={styles.titleWhite}>
          Balances
        </Typography>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={styles.divisorTop}
        >
          {
            Object.entries(record.attributes.balances || {}).map(([currency, amount]) => (
              <Grid
                key={currency + amount}
                item
                xs={12}
                sm={3}
                style={{ textAlign: 'center' }}
              >
                <BalanceField
                  source={currency.toLowerCase()}
                  label={''}
                  record={record}
                />
              </Grid>
            ))
          }
        </Grid>
      </CardContent>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h4" gutterBottom style={styles.titleWhite}>
              {
                (category === 'natural') ?
                  'Datos personales' :
                  'Datos de la empresa'
              }
            </Typography>
          </Grid>
        </Grid>
        {
          (category === 'natural') ?
            <NaturalUserData record={record} /> :
            <BusinessUserData record={record} />
        }
        {
          category === 'business' && ultimate_business_owners.length > 0 && (
            <UltimateBusinessOwners
              ultimate_business_owners={ultimate_business_owners}
            />
          )
        }
        {
          isAccess('show_attachment_file') && (
            <AttachmentsPanel record={props.record} readOnly={true} showTitle={true} />
          )
        }
        <PoliticallyExposedPersonInfo record={props.record} />
        <UserStatusLogTable logs={props.statusLogs.data} />
        <Grid container direction="row" justify="center" alignItems="center" style={styles.divisorTop}>
          <Grid item style={styles.divisor}>
            <Chip
              label="Declaración de origen de fondos"
              style={accept_funds ? styles.checked : styles.noChecked}
              avatar={
                <Avatar style={styles.avatar}>
                  {accept_funds ?
                    <CheckBoxIcon style={{ color: '#fff' }} /> :
                    <CheckBoxOutlineBlankIcon style={{ color: '#fff' }} />
                  }
                </Avatar>
              }
            />
          </Grid>
          <Grid item style={styles.divisor}>
            <Chip
              label="Declaración PEP"
              style={accept_pep ? styles.checked : styles.noChecked}
              avatar={
                <Avatar style={styles.avatar}>
                  {accept_pep ?
                    <CheckBoxIcon style={{ color: '#fff' }} /> :
                    <CheckBoxOutlineBlankIcon style={{ color: '#fff' }} />
                  }
                </Avatar>
              }
            />
          </Grid>
          <Grid item style={styles.divisor}>
            <Chip
              label="Declaración de información real"
              style={accept_real_info ? styles.checked : styles.noChecked}
              avatar={
                <Avatar style={styles.avatar}>
                  {accept_real_info ?
                    <CheckBoxIcon style={{ color: '#fff' }} /> :
                    <CheckBoxOutlineBlankIcon style={{ color: '#fff' }} />
                  }
                </Avatar>
              }
            />
          </Grid>
        </Grid>
      </CardContent>
      <Referrer referralUser={referral_user} referral_token={referral_token} modal={successMessage} />
      <CardContent>
        <NeitcomStatusLogs
          id={id}
          verification={criminal_verification['neitcom']}
          provider={'neitcom'}
          title={'Verificación Neitcom'}
        />
      </CardContent>
      {
        (
          availableCountriesUsersTruora.includes(business_user?.lr_document_issuing_country?.name) ||
          availableCountriesUsersTruora.includes(document_issuing_country_name)
        ) &&
        <CardContent>
          <NeitcomStatusLogs
            id={id}
            verification={criminal_verification['truora']}
            provider={'truora'}
            title={'Verificación Truora'}
          />
        </CardContent>
      }
      {
        isAccess('show_usd_account') && (
          <CardContent>
            <UsdNaturalAccount id={id} />
          </CardContent>
        )
      }
      <CardContent>
        <Typography variant="h4" gutterBottom style={styles.titleWhite}>
          Configuración de la cuenta
        </Typography>
        <Grid container direction="row" justify="center" alignItems="center" style={styles.divisorTop}>
          <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={3}
          >
            <TextField
              fullWidth
              value={TYPE_PROFILE_VERIFICATION[profile_verification]}
              label={'Cuenta Verificada'}
            />
          </Grid>
          <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={3}
          >
            <TextField
              fullWidth
              label={'Último acceso'}
              value={moment(last_sign_in_at).format('LL')}
            />
          </Grid>
          <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={3}
          >
            <TextField
              fullWidth
              label={'Último cambio de contraseña'}
              value={moment(password_last_changed_at).format('LL')}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center" style={styles.divisorTop}>
          <Grid style={styles.divisor}>
            <Chip
              label="Correo confirmado"
              style={email_confirmed ? styles.checked : styles.noChecked}
              avatar={
                <Avatar style={styles.avatar}>
                  {email_confirmed ?
                    <CheckBoxIcon style={{ color: '#fff' }} /> :
                    <CheckBoxOutlineBlankIcon style={{ color: '#fff' }} />
                  }
                </Avatar>
              }
            />
          </Grid>
          <Grid style={styles.divisor}>
            <Chip
              label="Doble autenticación"
              style={second_factor ? styles.checked : styles.noChecked}
              avatar={
                <Avatar style={styles.avatar}>
                  {second_factor ?
                    <CheckBoxIcon style={{ color: '#fff' }} /> :
                    <CheckBoxOutlineBlankIcon style={{ color: '#fff' }} />
                  }
                </Avatar>
              }
            />
          </Grid>
          <Grid style={styles.divisor}>
            <Chip
              label="Recepción automática"
              style={auto_convert_to_local_currency ? styles.checked : styles.noChecked}
              avatar={
                <Avatar style={styles.avatar}>
                  {auto_convert_to_local_currency ?
                    <CheckBoxIcon style={{ color: '#fff' }} /> :
                    <CheckBoxOutlineBlankIcon style={{ color: '#fff' }} />
                  }
                </Avatar>
              }
            />
          </Grid>
          <Grid style={styles.divisor}>
            <Chip
              label="Perfil completado"
              style={profile_completed ? styles.checked : styles.noChecked}
              avatar={
                <Avatar style={styles.avatar}>
                  {profile_completed ?
                    <CheckBoxIcon style={{ color: '#fff' }} /> :
                    <CheckBoxOutlineBlankIcon style={{ color: '#fff' }} />
                  }
                </Avatar>
              }
            />
          </Grid>
        </Grid>
      </CardContent>
      {devices_list && devices_list.length > 0 && (
        <CardContent>
          <Typography variant="h4" gutterBottom style={styles.titleWhite}>
            Lista de dispositivos
          </Typography>
          {devices_list.map((device) => (
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid
                key={device.id}
                style={styles.divisor}
                item
                xs={12}
                sm={3}
              >
                <TextField
                  fullWidth
                  value={device.id}
                  label={'Id'}
                  disabled
                />
              </Grid>
              <Grid
                key={device.id}
                style={styles.divisor}
                item
                xs={12}
                sm={3}
              >
                <TextField
                  fullWidth
                  value={device.name}
                  label={'Nombre'}
                  disabled
                />
              </Grid>
              <Grid
                key={device.id}
                style={styles.divisor}
                item
                xs={12}
                sm={3}
              >
                <TextField
                  fullWidth
                  value={device.last_time_active ? moment(device.last_time_active).format('LLLL') : ''}
                  label={device.last_time_active ? 'Último acceso' : 'Aún no registra accesos'}
                  disabled
                />
              </Grid>
              <Grid
                key={device.id}
                style={styles.divisor}
                item
                xs={12}
                sm={3}
              >
                <TextField
                  fullWidth
                  value={device.created_at ? moment(device.created_at).format('LLLL') : ''}
                  label={'Registro'}
                  disabled
                />
              </Grid>
            </Grid>
          ))}

        </CardContent>
      )}

      {/*       <CardContent>
        <Typography variant="h4" gutterBottom style={styles.titleWhite}>
          Billeteras
        </Typography>

        <Grid container direction="row" justify="center" alignItems="center" style={styles.divisorTop}>
          <Grid
            style={styles.divisor}
            item
            xs={12}
            sm={6}
          >
            <TextField
              fullWidth
              label={'Billetera BTC'}
              value={btc_address}
            />
          </Grid>

          <Grid style={styles.divisor}>
            <TextField
              label={'Billetera ETH'}
              value={eth_address}
            />
          </Grid>
        </Grid>
      </CardContent>  */}
      <CardContent>
        {/*  <Typography variant="h4" gutterBottom style={styles.titleWhite}>
          Billeteras Bitcoin
        </Typography> */}
        <Grid container direction="row" justify="center" alignItems="center" style={styles.divisorTop}>
          <Grid style={styles.divisor}>
            <BtcInformationUser user={id} />
            {/*  <BtcAddressView user={id} /> */}
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <Typography variant="h4" gutterBottom style={styles.titleWhite}>
          Otras billeteras
        </Typography>
        <Grid container direction="row" justify="center" alignItems="center" style={styles.divisorTop}>
          {
            country_iso_code == 'MX' &&
            (
              <>
                <Grid
                  style={styles.divisor}
                  item
                  xs={12}
                  sm={3}
                >
                  <TextField
                    fullWidth
                    value={'PagaCel'}
                    label={'Proveedor'}
                  />
                </Grid>
                <Grid
                  style={styles.divisor}
                  item
                  xs={12}
                  sm={3}
                >
                  {
                    stp_clabe_number ?
                      (
                        <TextField
                          fullWidth
                          value={
                            stp_clabe_number
                          }
                          label={'Cuenta CLABE'}
                        />
                      ) :
                      (
                        isAccess('create_clabe_accounts') ?
                          (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              {
                                isCreatingCLABE ?
                                  (
                                    <CircularProgress size={15} style={{ marginLeft: '10px', marginRight: '10px', verticalAlign: 'bottom' }} color={'secondary'} />
                                  ) :
                                  (
                                    <IconButton style={{ width: '15px', height: '15px', marginLeft: '10px', marginRight: '10px' }} onClick={assignSTPCLABENumber}>
                                      <CachedIcon style={{ verticalAlign: 'bottom', fontSize: 'large' }} />
                                    </IconButton>
                                  )
                              }

                              <Typography tyle={styles.titleWhite}>
                                Asignar cuenta CLABE
                              </Typography>
                            </div>
                          ) :
                          (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Typography gutterBottom style={styles.titleWhite}>
                                Permisos insuficientes
                              </Typography>
                            </div>
                          )
                      )
                  }

                </Grid>
              </>
            )
          }
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center" style={styles.divisorTop}>
          {
            crypto_accounts?.map((crypto_account) => {
              return (
                <>
                  <Grid
                    style={styles.divisor}
                    item
                    xs={12}
                    sm={3}
                  >
                    <TextField
                      fullWidth
                      value={`${crypto_account.provider_name?.toUpperCase()}${crypto_account.provider_name==='circle' ? ' (Legacy)' : '' }`}
                      label={'Proveedor'}
                    />
                  </Grid>
                  <Grid
                    style={styles.divisor}
                    item
                    xs={12}
                    sm={3}
                  >
                    <TextField
                      fullWidth
                      value={crypto_account.provider_account_id}
                      label={'Identificador del proveedor'}
                    />
                  </Grid>
                  <Grid
                    style={styles.divisor}
                    item
                    xs={12}
                    sm={3}
                  >
                    <TextField
                      fullWidth
                      value={crypto_account.status?.toUpperCase()}
                      label={'Estado'}
                    />
                  </Grid>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Dirección</TableCell>
                        <TableCell>Blockchain</TableCell>
                        <TableCell>Estado</TableCell>
                        <TableCell>Identificador del proveedor</TableCell>
                      </TableRow>
                    </TableHead>
                    {
                      <TableBody>
                        {
                          crypto_account.crypto_addresses.map((crypto_address) => {
                            return (
                              <TableRow key={crypto_address.id}>
                                <TableCell>{crypto_address.address}</TableCell>
                                <TableCell>{crypto_address.network_name}</TableCell>
                                <TableCell>{crypto_address.status?.toUpperCase()}</TableCell>
                                <TableCell>
                                  {crypto_address.external_provider_id}
                                  {
                                    crypto_address.url && (
                                      <a href={crypto_address.url || '#'} rel="noopener noreferrer" target="_blank">
                                        <OpenInNewIcon
                                          style={{ color: 'white', fontSize: 13, verticalAlign: 'top' }}
                                        />
                                      </a>
                                    )
                                  }
                                </TableCell>
                              </TableRow>
                            );
                          })
                        }
                      </TableBody>
                    }
                  </Table>
                </>
              );
            })
          }
        </Grid>
      </CardContent>
      <CardContent>
        <Typography variant="h4" gutterBottom style={styles.titleWhite}>
          Precios preferenciales
        </Typography>
        {preferential_prices && preferential_prices.map(item => {
          return (
            <Grid container direction="row" justify="left" alignItems="center" style={styles.divisorTop}>
              <Grid
                style={styles.divisor}
                item
                xs={12}
                sm={2}
              >
                <TextField
                  fullWidth
                  value={item.origin_currency}
                  label={'Origen'}
                />
              </Grid>
              <Grid
                style={styles.divisor}
                item
                xs={12}
                sm={2}
              >
                <TextField
                  fullWidth
                  label={'Destino'}
                  value={item.country_name || item.destination_currency}
                />
              </Grid>
              <Grid
                style={styles.divisor}
                item
                xs={12}
                sm={2}
              >
                <TextField
                  fullWidth
                  label={'Tipo Preferencial'}
                  value={MAP_PREFERENTIAL_TYPES_TO_NAMES[item.preferential_type]}
                />
              </Grid>
              <Grid
                style={styles.divisor}
                item
                xs={12}
                sm={2}
              >
                <TextField
                  fullWidth
                  label={'Código'}
                  value={`${item.origin_currency}${item.destination_currency}`.toUpperCase()}
                />
              </Grid>
              <Grid
                style={styles.divisor}
                item
                xs={12}
                sm={2}
              >
                <TextField
                  fullWidth
                  label={MAP_PREFERENTIAL_TYPES_TO_NAMES[item.preferential_type]}
                  value={item.preferential_type === "exchange" || item.preferential_type === "price" ? item.sell_fee : item[item.preferential_type]}
                />
              </Grid>
              <Grid
                style={styles.divisor}
                item
                xs={12}
                sm={2}
              >
                <TextField
                  fullWidth
                  label={'Monto Preferencial'}
                  value={item.preferential_price}
                />
              </Grid>
            </Grid>
          )
        })}
      </CardContent>
      {category === 'business' && (
        <CardContent>
          <ListLinks
            title={'Enlaces de pago generados'}
            showTable={false}
            loadConfig={false}
            {...props}
          />
        </CardContent>
      )}
      <Cards
        listCardActive={list_card_active}
        listCardNotActive={list_card_not_active}
      />
    </Card>
  );
};

Show.defaultProps = {
  record: {
    id: '',
    attributes: {
      first_name: '',
      last_name: '',
      birthdate: '',
      password_last_changed_at: '',
      last_sign_in_at: '',
      city: '',
      address: '',
      email_confirmed: '',
      default_currency: '',
      email: '',
      document_number: '',
      document_expedition_date: '',
      auto_convert_to_local_currency: '',
      document_type: '',
      accept_funds: '',
      accept_pep: '',
      profile_completed: '',
      second_factor: '',
      accept_real_info: '',
      automatic_verification: '',
      biometric_verification: '',
      document_image_url: '',
      profile_verification: '',
      stp_clabe_number: '',
      account_bank: {
        bank: '',
        type_account_bank: '',
        account_bank: '',
      },
    },
    country: {
      attributes: {
        name: '',
      },
    },
  }
};

export default Show;
import React, { useState, useMemo } from 'react';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import ReactCountryFlag from 'react-country-flag';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CachedIcon from '@material-ui/icons/Cached';
import { CircularProgress } from '@material-ui/core';
import { TYPE_PROFILE_VERIFICATION, CATEGORY } from '../../../../Utils';
import { formatMoney } from '../../../../Helpers/MoneyHelper';

import { availableCountriesUsersTruora, biometricStatus } from '../../../../Config/constants';
import useAccessRole from '../../../../hooks/useAccessRole';
import useAlerts from '../../../../hooks/useAlerts';
import { UserProvider } from '../../../../Providers';
import Swal from 'sweetalert2';

import { useReset } from "../../../../hooks";
import { NeitcomStatus } from '../../../../Components';

moment.locale('es');
const UserInfo = (props) => {
  const { isAccess } = useAccessRole();
  const { record: {
    id,
    attributes: {
      total_sent_this_month,
      email,
      first_name,
      last_name,
      created_at,
      iso_code_country,
      status,
      biometric_verification,
      source_biometric,
      rejected_note,
      criminal_verification_status,
      referrer_information,
      category,
      document_issuing_country_name,
      business_user,
      profile_verification,
      usd_account_status,
    },
    relationships: {
      referrals,
    },
  } } = props;

  const { neitcom, truora } = criminal_verification_status || {};
  const [isReferrals, setReferrals] = useState(false);
  const { resetList } = useReset();
  const [isReloadingBiometric, setIsReloadingBiometric] = useState(false);
  const { showSelect, successMessage, errorMessage } = useAlerts();

  const reload_biometric_status = async () => {
    try {
      setIsReloadingBiometric(true);
      await UserProvider.reloadBiometricVerification(id);
      setIsReloadingBiometric(false);
      resetList();
    } catch (error) {
      setIsReloadingBiometric(false);
      Swal.fire({
        title: 'No fue posible sincronizar con el proveedor de biometría',
        text: error.data.message,
        icon: 'error',
      });
    }
  }

  const onChangeCategory = async () => {
    try {
      const params = {
        text: 'Ingrese nueva categoria',
        inputPlaceholder: 'Categoria',
        inputValue: 'nartural',
        inputOptions: (() => {
          const options = {};
          CATEGORY.forEach(c => {
            options[c.id] = c.name
          });
          return options;
        })(),
        confirmButtonText: 'Confirmar'
      };
      const { isConfirmed, value } = await showSelect(params);

      if (isConfirmed) {
        const data = await UserProvider.updateCategory(id, value);
        await UserProvider.resetUserSession(id);

        successMessage(data.data.message);
        resetList();
      }
    } catch (error) {
      errorMessage(error.data.message);
    }

  }
  const referrer_info = useMemo(() => {
    return (
      referrer_information && referrer_information.hasOwnProperty('email_referrer')
        ? `${referrer_information['first_name_referrer']} ${referrer_information['last_name_referrer']} (${referrer_information['email_referrer']})`
        : null
    );
  }, [referrer_information]);

  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {iso_code_country && (
          <ReactCountryFlag countryCode={iso_code_country.toUpperCase() === 'COCOP' ? 'CO' : iso_code_country.toUpperCase()} style={{ fontSize: '5em' }} svg />
        )}
        {iso_code_country.toUpperCase() === 'COCOP' && <div style={{
          fontSize: 30, width: 40, height: 64,
          display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}>2</div>}
      </div>
      <div style={{ textAlign: 'left', fontSize: '1rem' }}>
        {(first_name || last_name) && (
          <div style={{ fontWeight: 'bold' }}>
            {first_name?.toUpperCase()} {last_name?.toUpperCase()} {(biometric_verification === 'biometric_accepted' || biometric_verification === 'biometric_rejected') ? <CheckBoxIcon style={{ color: (biometric_verification === 'biometric_accepted' ? 'green' : 'red') }} /> : <></>}
          </div>
        )}
      </div>
      <div style={{ textAlign: 'left' }}>
        <div>
          {email}
        </div>
      </div>
      <div style={{ textAlign: 'left', paddingBottom: 10, paddingTop: 10 }}>
        Estado de verificación de perfil
        <div>{TYPE_PROFILE_VERIFICATION[profile_verification]}</div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'left' }}>
        {
          (category === 'natural') ?
            <div style={{ fontWeight: 'bold', color: '#00d9ff', marginRight: 12 }}>
              Usuario natural
            </div> :
            <div style={{ fontWeight: 'bold', color: '#FFD96A', marginRight: 12 }}>
              Usuario business
            </div>
        }
        {isAccess('edit_user_category') && <div className='clickable' onClick={onChangeCategory}>🖊</div>}
      </div>
      <div>
        <NeitcomStatus
          veredict={neitcom?.['veredict']}
          has_ignored_items={neitcom?.['has_ignored_items']}
          max_percentage={neitcom?.['max_percentage']}
          provider={'neitcom'}
        />
      </div>
      {
        (
          availableCountriesUsersTruora.includes(business_user?.lr_document_issuing_country_name) ||
          availableCountriesUsersTruora.includes(document_issuing_country_name)
        ) &&
        <div>
          <NeitcomStatus
            veredict={truora?.['veredict']}
            has_ignored_items={truora?.['has_ignored_items']}
            max_percentage={truora?.['max_percentage']}
            provider={'truora'}
          />
        </div>
      }
      <div style={{ paddingTop: 20 }}>
        Verificación Biométrica
        {isReloadingBiometric && <CircularProgress size={15} style={{ marginLeft: '10px', verticalAlign: 'bottom' }} color={'secondary'} />}
        {
          (
            (biometric_verification === 'biometric_pending') &&
            (!isReloadingBiometric) &&
            isAccess('reload_biometric_verification')
          ) ? (
            <>
              <IconButton style={{ width: '15px', height: '15px', marginLeft: '10px' }} onClick={reload_biometric_status}>
                <CachedIcon style={{ verticalAlign: 'bottom', fontSize: 'large' }} />
              </IconButton>
            </>
          ) : <></>
        }
        <div>{biometricStatus[biometric_verification]}</div>
        {
          biometric_verification !== 'biometric_none' && biometric_verification !== 'false' &&
          (<div>Solicitud desde {source_biometric}</div>)
        }
      </div>
      <div style={{ paddingTop: 20 }}>
        Estado de cuenta de EE.UU.
        <div>{usd_account_status}</div>
      </div>
      <div style={{ paddingTop: 20 }}>
        Fecha de registro
        <div>{moment.utc(created_at).format('LL')}</div>
      </div>
      {status !== 'profile_review' && (
        <div style={{ paddingTop: 20 }}>
          Estado
          <div>{status}</div>
        </div>
      )}
      {status === 'profile_review' && (
        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
          Estado
          <div style={{ color: '#00d9ff', fontSize: 18, fontWeight: 'bold' }}>REVISIÓN EXTERNA DEL PERFIL</div>
        </div>
      )}
      {rejected_note && (
        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
          Motivo de rechazo
          <div>{rejected_note}</div>
        </div>
      )}
      <div style={{ paddingTop: 20 }}>
        Total enviado este mes
        <div style={{ fontSize: 30, color: '#00d9ff', fontWeight: 'bold' }}>{total_sent_this_month ?
          `${formatMoney(total_sent_this_month, "usd")} USD` :
          `${formatMoney(0, "usd")} USD`}</div>
      </div>
      {
        !!referrer_info && (
          <div style={{ textAlign: 'left', paddingTop: 10 }}>
            Referido por
            <div>{referrer_info}</div>
          </div>
        )
      }
    </div>
  );
};

export default UserInfo;

import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button, FormControlLabel, Switch } from "@material-ui/core";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Swal from "sweetalert2";

import useModal from "../../../../hooks/useModal";
import useAccessRole from "../../../../hooks/useAccessRole";
import ModalConfirm from "../../../../Components/ModalConfirm";
import { flagsCountries } from "../../../../Config/constants";
import { TransactionProvider } from "../../../../Providers";
import { WithdrawalProvider } from "../../../../Providers";
import { useReset, useAlerts } from "../../../../hooks";
import { toMoney } from "../../../../Helpers/MoneyHelper";
import DetailsRecipient from "../DetailsRecipient";
import { createBrowserHistory as createHistory } from 'history';

import styles from "./styles";

const MakeWithdrawalButton = (props) => {
  const {
    record,
    transaction,
    isTransferCountry,
    isTransaction,
  } = props;
  const {
    id = "",
    attributes: {
      currency,
      category,
      status,
      is_the_payment_in_process,
      account_bank,
      external_id,
      locking_status,
      available_for_automatic_withdrawal,
      payment_processors,
      external_provider,
      beneficiary,
    },
  } = transaction;

  const { resetList } = useReset();
  const { errorMessage, successMessagePayment, successMessage } = useAlerts();
  const [beneficiaryAmount, setBeneficiaryAmount] = useState(null)
  const history = createHistory({ forceRefresh: true });

  const name = record.country_data["attributes"]
    ? record.country_data["attributes"].name
    : "Estados Unidos";
  const [disableButtonAutomaticPayment, setDisableAutomaticPayment] =
    useState(false);
  const hook = useModal();
  const { showModal } = hook;

  const { isAccess } = useAccessRole();

  const lemonOrMercadoPago = useMemo(() => account_bank.account_bank.bank === 'Mercado Pago' || account_bank.account_bank.bank === 'Lemon' ? true : false, [account_bank.iso_code_country === 'AR'])

  if (!record.country_data["attributes"]) {
    return null;
  }

  const showError = (error) => {
    NotificationManager.error(error, "", 5000, () => { });
  };

  const loading = () => {
    Swal.fire({
      title: 'Loading ...',
      html: 'Please wait...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
  }

  const accept = async (processor = '') => {
    loading()

    try {
      setDisableAutomaticPayment(true);
      await TransactionProvider.acceptedWithdawal(id, processor);
      Swal.hideLoading()
      resetList();
      successMessagePayment();
    } catch (error) {
      Swal.hideLoading();
      errorMessage(error.data.message);
      setDisableAutomaticPayment(false);
      setTimeout(() => {
        resetList();
      }, 1000);
    }
  };

  const acceptHandler = (is_bancolombia = null) => {
    Swal.fire({
      text: "¿Desea hacer el pago automático?",
      showCancelButton: true,
      confirmButtonText: "SI",
      cancelButtonText: "NO"
    }).then((confirm) => {
      if (confirm.isConfirmed) {
        accept(is_bancolombia);
      }
    });
  };

  const modalParameter = async (description, external_id, id, value) => {
    let data = {
      description,
      external_id,
      id,
      value,
    };

    try {
      loading();
      await WithdrawalProvider.changeStatus(data);
      successMessage();
      Swal.hideLoading();
      resetList();


    } catch (error) {
      Swal.hideLoading();
      Swal.fire({
        icon: 'error',
        text: error.data.message,
      })
    }
  };

  const acceptManualHandler = (e) => {
    let value =
      e.target.innerText.toLowerCase() === "marcar completada" ? 0 : 1;

    Swal.fire({
      text: `${external_id
        ? "Este retiro tiene asociado el id de proveedor " +
        external_id +
        ". Por favor ingresa una descripción"
        : "Por favor ingresa una descripción"
        }`,
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Siguiente",
      cancelButtonText: "Cancelar",
      showLoaderOnConfirm: true,
    }).then((description_result) => {
      if (description_result.isConfirmed) {
        if (!external_id) {
          Swal.fire({
            text: "Por favor ingrese el id del proveedor",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Siguiente",
            showLoaderOnConfirm: true,
          }).then((id_result) => {
            if (id_result.isConfirmed) {
              Swal.fire({
                text: `${value === 0
                  ? "¿Desea marcar como completada?"
                  : "¿Desea marcar como procesada?"
                  }`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: "Cancelar",
                confirmButtonText: "Sí",
              }).then((result) => {
                if (result.isConfirmed) {
                  let external_id = id_result.value ? id_result.value : null;
                  let description = description_result.value
                    ? description_result.value
                    : null;
                  modalParameter(description, external_id, id, value);
                } else {
                  errorMessage();
                }
              });
            }
          });
        } else {
          Swal.fire({
            text: `${value === 0
              ? "¿Desea marcar como completada?"
              : "¿Desea marcar como procesada?"
              }`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Sí",
          }).then((result) => {
            if (result.isConfirmed) {
              let description = description_result.value
                ? description_result.value
                : null;

              modalParameter(description, null, id, value);
            }
          });
        }
      }
    });
  };

  const reject = async (description_state_change) => {
    loading();

    try {
      await TransactionProvider.rejectedWithdrawal(id, description_state_change);
      Swal.hideLoading();
      resetList();
      successMessage('Transacción rechazada correctamente');
    } catch (error) {
      Swal.hideLoading();
      errorMessage(error.data.message);
      setTimeout(() => {
        resetList();
      }, 1000);
    }
  };

  const rejectHandler = () => {
    Swal.fire({
      text: "Por favor ingresa la razón del rechazo",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      preConfirm: (value) => {
        if (!value.trim()) {
          Swal.showValidationMessage('La razón del rechazo es mandatoria para continuar');
        }
      },
      confirmButtonText: "Siguiente",
      cancelButtonText: "Cancelar",
      showLoaderOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        reject(result.value);
      }
    })
  };

  const cancelPayment = async () => {
    loading();

    try {
      await TransactionProvider.cancelPayment(id);
      Swal.hideLoading();
      resetList();
    } catch (error) {
      Swal.hideLoading();
      showError("error");
      setTimeout(() => {
        resetList();
      }, 1000);
    }
  };

  const cancelPaymentHandler = async () => {
    await showModal("¿Desea cancelar el pago?", cancelPayment);
  };

  const validPayMobile = (account_bank) => {
    return account_bank.account_bank && account_bank.account_bank.transfer_type ?
      (account_bank.account_bank.transfer_type !== 'Pago movil') : true;
  };

  const makePayment = async () => {
    try {
      const { isConfirmed = false, value = '' } = await Swal.fire({
        text: `Por favor ingrese el procesador de pago`,
        input: 'select',
        inputOptions: payment_processors,
        inputPlaceholder: 'Seleccione el procesador de pago',
        showCancelButton: true,
        confirmButtonText: "Siguiente",
        cancelButtonText: "Cancelar",
        showLoaderOnConfirm: true,
        inputValue: 'manual',
      });

      if (isConfirmed && value) {
        accept(value)
      }
    } catch (error) {
    }
  };

  if (payment_processors && status !== "completed" && status !== "denied" && !external_provider) {
    return (
      <>
        {
          isAccess("approve_withdrawal") ?
            (
              <Button
                onClick={makePayment}
                style={styles.buttonGreen}
                disabled={disableButtonAutomaticPayment}
              >
                REALIZAR PAGO
              </Button>
            ) : null
        }
      </>
    );
  }

  const handleChangeLockedStatus = async () => {
    Swal.showLoading();
    const new_locking_status = locking_status === 'unlocked' ? 'locked_by_stardard_flow' : 'unlocked';
 
    try {
      await TransactionProvider.updateLockedStatus(id, { locking_status: new_locking_status });
      resetList();
      Swal.close();
    } catch (error) {
      Swal.close();
      Swal.fire(
        'Lo sentimos, algo no fue bien',
        'No fue posible cambiar el estado de bloqueo de este registro.',
        'error',
      )
    }
  }

  const getTotalAmountRecipient = async (e) => {
    try {
      const response = await TransactionProvider.getTotalAmountRecipient(account_bank.document_number, account_bank.iso_code_country);
      if (response) {
        let message = '';
        const balance = response.data.balance;
        setBeneficiaryAmount(`${toMoney(response.data.balance.amount, response.data.balance.currency)} ${response.data.balance.currency}`)
      }
    } catch (error) {
      Swal.fire(
        'Balance',
        error.data.error.message ? error.data.error.message : error,
        'info'
      )
    }
  };

  const goToFilterTransaction = (document_number) => window.open(`/transacciones?filter={"document_number"%3A"${document_number}"}`, '_blank');

  if (is_the_payment_in_process && isAccess("decline_withdrawal")) {
    return (
      <div>
        <Button onClick={cancelPaymentHandler} style={styles.buttonRed}>
          CANCELAR PAGO
        </Button>
      </div>
    );
  }
  if (status !== "completed" && status !== "denied")
    return (
      <div>
        <ModalConfirm {...hook} />
        <NotificationContainer />
        {
          isAccess('edit_transaction_locked_status') && (
            <div style={{ display: 'block' }}>
              <FormControlLabel
                control={
                  <Switch checked={locking_status !== 'unlocked'} onChange={handleChangeLockedStatus} />
                }
                label={locking_status !== 'unlocked' ? 'Desbloquear' : 'Bloquear'}
              />
            </div>
          )
        }

        {
          (!account_bank && isTransferCountry(flagsCountries[name])) ||
            (status !== "processed" && account_bank &&
              isTransferCountry(
                account_bank.iso_code_country === 'CO'
                  ? 'COCOP' : account_bank.iso_code_country
              ) &&
              isAccess("approve_withdrawal") &&
              validPayMobile(account_bank) &&
              available_for_automatic_withdrawal
            ) ? (
            <>
              <Button
                onClick={() => { acceptHandler(null) }}
                style={locking_status !== 'unlocked'
                  ? styles.buttonDisabled
                  : styles.buttonGreen
                }
                disabled={disableButtonAutomaticPayment || locking_status !== 'unlocked'}
              >
                PAGO AUTOMÁTICO
              </Button>
              <div style={styles.separator} />
            </>
          ) : null
        }
        {
          (!account_bank && isTransferCountry(flagsCountries[name])) ||
            (status !== "processed" && account_bank &&
              isTransferCountry(
                account_bank.iso_code_country === 'CO'
                  ? 'COCOP' : account_bank.iso_code_country
              ) &&
              isAccess("approve_withdrawal") &&
              validPayMobile(account_bank) &&
              available_for_automatic_withdrawal &&
              account_bank.iso_code_country === 'CO' ||
              account_bank.iso_code_country === 'COCOP' &&
              account_bank.account_bank.bancolombia_code
            ) ? (
            <>
              <Button
                onClick={() => { acceptHandler(true) }}
                style={locking_status !== 'unlocked'
                  ? styles.buttonDisabled
                  : styles.buttonYellow
                }
                disabled={disableButtonAutomaticPayment || locking_status !== 'unlocked'}
              >
                PAGO AUTOMÁTICO CON BANCOLOMBIA
              </Button>
              <div style={styles.separator} />
            </>
          ) : null
        }
        {
          isAccess("approve_withdrawal") ?
            (
              <Button
                onClick={acceptManualHandler}
                style={locking_status !== 'unlocked'
                  ? styles.buttonDisabled
                  : styles.buttonBlue
                }
                disabled={locking_status !== 'unlocked'}
              >
                MARCAR COMPLETADA
              </Button>
            ) : null
        }
        <div style={styles.separator} />
        {
          status === "pending" &&
          ((account_bank.iso_code_country.toUpperCase() === "CO") ||
            account_bank.iso_code_country.toUpperCase() === "COCOP" ||
            account_bank.iso_code_country.toUpperCase() === "VE" ||
            lemonOrMercadoPago
          ) && (
            <>
              <div style={styles.separator} />
              <Button
                onClick={acceptManualHandler}
                style={locking_status !== 'unlocked'
                  ? styles.buttonDisabled
                  : styles.buttonPurple
                }
                disabled={locking_status !== 'unlocked'}
              >
                MARCAR PROCESADA
              </Button>
            </>
          )
        }
        {
          isAccess("decline_withdrawal")
          && (
            <Button
              onClick={rejectHandler}
              style={styles.buttonRed}
            >
              RECHAZAR PAGO
            </Button>
          )
        }
        <DetailsRecipient
          beneficiaryAmount={beneficiaryAmount}
          isTransaction={isTransaction}
          getTotalAmountRecipient={getTotalAmountRecipient}
          beneficiary={beneficiary}
          goToFilterTransaction={goToFilterTransaction}
        />
      </div>
    );

  return null;
};

MakeWithdrawalButton.propTypes = {
  transaction: PropTypes.object,
  history: PropTypes.object,
  isTransferCountry: PropTypes.func,
  isTransaction: PropTypes.bool,
};

MakeWithdrawalButton.defaultProps = {
  isTransferCountry: () => {
    return false;
  },
  isTransaction: false
};

export default MakeWithdrawalButton;

import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

import useAlerts from "./useAlerts";

const useExportFiles = () => {
    const { successMessage } = useAlerts();
    
    const exportFilesSimple = (posts, fieldsList) => {
        if (posts.length < 1) return;
        const postsForExport = posts.map(post => post);
        const csv = convertToCSV({
            data: postsForExport,
            fields: fieldsList,
        });
        downloadCSV(csv, 'exportFile');
        successMessage('Petición exitosa');
    }

    const exportFilesAttributes = (posts, fieldsList) => {
        if (posts.length < 1) return;
        const postsForExport = posts.map(post => post.attributes);
        const csv = convertToCSV({
            data: postsForExport,
            fields: fieldsList,
        });
        downloadCSV(csv, 'exportFile');
        successMessage('Petición exitosa');
    }

    const exportFileCardAccount = (posts, fieldsList) => {
        if (posts.length < 1) return;
        const postsForExport = posts.map(post => {
            const { id, type, attributes, ...forExport } = post; 
            forExport.id = post.id; 
            forExport.type = post.type; 
            forExport.email = post.attributes.user.email; 
            forExport.first_name = post.attributes.user.first_name; 
            forExport.last_name = post.attributes.user.last_name; 
            forExport.created_at = post.attributes.created_at; 
            forExport.status = post.attributes.status; 
            forExport.provider = post.attributes.provider; 
            forExport.card_requests_status = post.attributes.card_requests && post.attributes.card_requests.status;
            forExport.card_requests_id = post.attributes.card_requests && post.attributes.card_requests.id;
            forExport.card_type = post.attributes.card_requests && post.attributes.card_requests.card_type;
            forExport.card_requests_id_created_at = post.attributes.card_requests && post.attributes.card_requests.created_at; 

            return forExport;
        });
      
        const csv = convertToCSV({
            data: postsForExport,
            fields: fieldsList,
        });
        downloadCSV(csv, 'exportFilesCardAccount');
        successMessage('Petición exitosa');
    }

    const exportFileBusiness = (posts, fieldsList) => {
        if (posts.length < 1) return;
        const postsForExport = posts.map(post => {
            const { id, type, attributes, ...forExport } = post;
            forExport.id = post.id;
            forExport.type = post.type;
            forExport.default_currency = post.attributes.default_currency;
            forExport.wallet_balance = JSON.stringify(post.attributes.wallet_balance);
            forExport.assumes_recharge_commissions = post.attributes.assumes_recharge_commissions;
            forExport.first_name = post.attributes.first_name;
            forExport.last_name = post.attributes.last_name;
            forExport.country_name = post.attributes.country_name;
            forExport.email = post.attributes.email;
            forExport.balances = JSON.stringify(post.attributes.balances) 
            forExport.wallets_balance = post.attributes.wallets_balance;
            forExport.is_price_update_for_master_wallet = post.is_price_update_for_master_wallet;
            forExport.is_trx_status_recipient_email = post.is_trx_status_recipient_email;

            return forExport;
        });

        const csv = convertToCSV({
            data: postsForExport,
            fields: fieldsList,
        });
        downloadCSV(csv, 'exportFileBusiness');
        successMessage('Petición exitosa');
    }

    const exportFileRequest = (posts, fieldsList) => {
        if (posts.length < 1) return;
        const postsForExport = posts.map(post => {
            const { id, type, attributes, ...forExport } = post;
            forExport.id = post.id;
            forExport.type = post.type;
            forExport.response_time = post.attributes.response_time;
            forExport.created_at = post.attributes.created_at;
            forExport.provider = post.attributes.provider;
            forExport.is_successful = post.attributes.is_successful;
            forExport.target_type = post.attributes.target_type;
            forExport.target_id = post.attributes.target_id;
            forExport.url = post.attributes.url;
            forExport.method = post.attributes.method;
            forExport.successful_message = post.attributes.successful_message;
            forExport.error_message = post.attributes.error_message;
            forExport.error_code = post.attributes.error_code;
            forExport.request_and_response = post.attributes.request_and_response && JSON.stringify(post.attributes.request_and_response[0].request);

            return forExport;
        });

        const csv = convertToCSV({
            data: postsForExport,
            fields: fieldsList
        });
        downloadCSV(csv, 'exportFileRequest');
        successMessage('Petición exitosa');
    }

    const exportFileTransactionStatus= (posts, fieldsList) => {
        if (posts.length < 1) return;
        const postsForExport = posts.map(post => {
            const { id, type, attributes, ...forExport } = post;
            
            forExport.id = post.id;
            forExport.type = post.type;
            forExport.created_at = post.attributes.created_at;
            forExport.responsable_cambio = post.attributes.user ? post.attributes.user.email : null;
            forExport.status = post.attributes.status;
            forExport.transaction_operation_type = post.attributes.transaction_operation_type;
            forExport.description = post.attributes.description;
            forExport.transaction_id = post.attributes.original_transaction.id;
            forExport.id_proveedor = post.attributes.original_transaction.external_id;
            forExport.transactions_status_url = post.transactions_status_url;

            return forExport;
        });

        const csv = convertToCSV({
            data: postsForExport,
            fields: fieldsList,
        });
        downloadCSV(csv, 'exportFileTransactionStatus');
        successMessage('Petición exitosa');
    }

    const exportFileSegmentos = (posts, fieldsList) => {
        if (posts.length < 1) return;
        const postsForExport = posts.map(post => {
            const { id, type, attributes, ...forExport } = post;

            forExport.id = post.id;
            forExport.type = post.type;
            forExport.name = post.attributes.name;
            forExport.created_at = post.attributes.created_at;
            forExport.status = post.attributes.status;

            return forExport;
        });

        const csv = convertToCSV({
            data: postsForExport,
            fields: fieldsList,
        });
        downloadCSV(csv, 'exportFileSegmentos');
        successMessage('Petición exitosa');
    }

    const exportFileCoupons = (posts, fieldsList) => {
        if (posts.length < 1) return;
        const postsForExport = posts.map(post => {
            const { id, type, attributes, ...forExport } = post;
            forExport.id = post.id;
            forExport.type = post.type;
            forExport.identifier = post.attributes.identifier;
            forExport.campaign = post.attributes.campaign;
            forExport.start_date = post.attributes.start_date;
            forExport.end_date = post.attributes.end_date;
            forExport.number_coupons = post.attributes.number_coupons;
            forExport.bonus_type = post.attributes.bonus_type;
            forExport.min_amount_transaction = post.attributes.min_amount_transaction;
            forExport.status = post.attributes.status;
            forExport.amount = post.attributes.amount;
            forExport.segment_id = post.attributes.segment ? post.attributes.segment.id : null;
            forExport.segment_name = post.attributes.segment ? post.attributes.segment.name: null ;
            forExport.dest_country_name = post.attributes.dest_country ? post.attributes.dest_country.name : null;
            forExport.origen_currency = post.attributes.dest_country ? post.attributes.dest_country.currency : null;

            return forExport;
        });
        
        const csv = convertToCSV({
            data: postsForExport,
            fields: fieldsList,
        });
        downloadCSV(csv, 'exportFileCoupons');
        successMessage('Petición exitosa');
    }

    const exportFileWallets = (posts, fieldsList) => {
        if (posts.length < 1) return;
        const postsForExport = posts.map(post => {
            const { id, type, attributes, ...forExport } = post;
            forExport.id = post.id;
            forExport.type = post.type;
            forExport.is_master = post.attributes.is_master;
            forExport.uuid = post.attributes.uuid;
            forExport.balance = post.attributes.balances[post.attributes.default_currency.toLowerCase()];
            forExport.default_currency = post.attributes.default_currency;
            forExport.token = post.attributes.token;

            return forExport;
        });

        const csv = convertToCSV({
            data: postsForExport,
            fields: fieldsList,
        });
        downloadCSV(csv, 'exportFileWallets');
        successMessage('Petición exitosa');
    }

    const exportFileBusinessTransactions = (posts, fieldsList) => {
        if (posts.length < 1) return;
        const postsForExport = posts.map(post => {
            const { id, type, attributes, ...forExport } = post;
            forExport.id = post.id;
            forExport.type = post.type;
            forExport.Moneda = post.attributes.currency;
            forExport.Categoria = post.attributes.category;
            forExport.Estado = post.attributes.status
            forExport.Emisor = post.attributes.sender_uuid;
            forExport.Receptor = post.attributes.recipient_uuid;
            forExport.Orden = post.attributes.order;
            forExport.transacción_vita = post.attributes.transaction_id;
            forExport.Fee = JSON.stringify(`${post.attributes.fee_value} ${post.attributes.currency}`) 
            forExport.Total_fee = JSON.stringify(`${post.attributes.total_fee} ${post.attributes.currency}`) 
            forExport.Monto = JSON.stringify(`${post.attributes.amount} ${post.attributes.currency}`) 
            forExport.Total = JSON.stringify(`${post.attributes.total} ${post.attributes.currency}`) 
            forExport.URL = post.attributes.notification_url;
            forExport.IPN = post.attributes.is_ipn_sent;

            return forExport;
        });

        const csv = convertToCSV({
            data: postsForExport,
            fields: fieldsList,
        });
        downloadCSV(csv, 'exportFileBusinessTransactions');
        successMessage('Petición exitosa');
    }

    return{
        exportFilesAttributes,
        exportFilesSimple,
        exportFileCardAccount,
        exportFileBusiness,
        exportFileRequest,
        exportFileTransactionStatus,
        exportFileSegmentos,
        exportFileCoupons,
        exportFileWallets,
        exportFileBusinessTransactions,
    }
}

export default useExportFiles;